.project-page-content {
  display: flex;
  height: 100vh;
  width: 100vw;

  .project-sidebar {
    display: inline-block;
    background-color: $grey-0;
    width: 300px;
    min-width: 300px;
    padding: 0 10px;
    box-shadow: 3px 0 3px $grey-2;
    z-index: 10;

    @include breakpoint(sm) {
      display: none;
    }

    .summary-wrap {
      display: flex;
      flex-direction: column;
      position: fixed;
      width: 300px;
      height: 100%;
      margin-left: -10px;
      padding: 0 20px;
      overflow-y: auto;
      overflow-x: hidden;
      
      > * {
        flex-shrink: 0;
      }
      
      .share-list {
        margin: 12px 0;
        padding: 0 20px;
        
        .organisation-image {
          display: inline-block;
          height: 48px;
          width: 48px;
          margin-right: -8px;
        }
      }

      .summary-title {
        line-height: 1;
        font-size: 45px;
        font-weight: 400;
        margin-top: 0;
        margin-bottom: 2vh;
        padding: 0 20px;
      }
      .summary-job-number{
        line-height: 1;
        font-size: 22px;
        font-weight: 400;
        margin-top: 0;
        margin-bottom: 1vh;
        padding: 0 20px;
      }

      .summary-text {
        margin-bottom: 30px;
        padding: 0 20px;
        font-size: 15px;
      }
      
      .project-edit-status {
        padding: 0 20px;
        font-style: italic;
        div {
          margin: 0 !important;
          float: left;
        }
      }
      
      .project-notes-btn {
        align-self: flex-start;
        justify-content: flex-start;
        border: 1px solid $grey-8;
        background: white;
        padding: 9px 24px 9px 19px;
        margin: 0 20px;
        
        &:hover {
          background: $grey-2;
          border-color: $black;
          padding: 9px 29px 9px 14px;
        }
      }

      .summary-btn-wrap {
        flex-grow: 1;
        width: 300px;
        margin-left: -20px;
        border-bottom: 1px solid $grey-2;
        justify-content: center;
        display: flex;
        flex-direction: column;

        .summary-btn {
          text-align: center;
          padding: 0 20px;
          color: $grey-6;
          margin-bottom: 20px;
          margin-left: 20px;

          &:hover, &:focus {
            border: none;
            text-decoration: none;
            color: black;
          }
        }
      }

      .price-wrap {
        color: $grey-6;

        .quote-title {
          line-height: 1;
          font-size: 32px;
          font-weight: 400;
          margin-top: 40px;
          margin-bottom: 30px;
        }
        
        .rebuild-required-note {
          margin-top: -10px;
          margin-bottom: 20px;
          color: $color-brand-01;
          
          &:before {
            margin-right: 1rem;
          }
        }
      }

      .quote-wrap {
        color: $grey-6;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        font-size: 15px;

        &.total {
          background-color: $grey-6;
          width: 300px;
          margin-top: 20px;
          margin-left: -20px;
          margin-bottom: 0;
          color: white;
          padding: 20px 20px;
        }

        &.edited:not(.total) .quote-price {
          color: $color-brand-01;
        }
      }
    }

    .back-btn-wrap {
      width: 300px;
      margin-left: -20px;

      .back-btn {
        text-align: center;
        color: $grey-6;
        margin-left: 20px;
        margin-top: 5px;

        &:hover, &:focus {
          border: none;
          text-decoration: none;
          color: black;
        }
      }
    }
  }

  .main-content {
    display: inline-flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    vertical-align: top;
    overflow: auto;
    transition: 0.3s ease all;

    .project-page-buttons {
      display: flex;
      justify-content: flex-end;
      padding: 10px;
      flex-wrap: wrap;

      &.quote-active {
        background-color: $grey-0;
        padding-top: 0;
      }

      .search-key-input {
        margin: 0 auto 0 0;
        
        input {
          margin: 0;
        }
      }

      @include breakpoint(sm) {
        
        button {
          width: 100%;
          padding: 12px;
          margin: 1px;
        }

        &.quote-active {
          padding-top: 10px;
        }

        .search-key-input {
          margin: 0 0 8px;
          width: 100%;
        }
      }

      .build-wrap {
        flex-grow: 1;
        
        button {
          //transition: none;
          margin-right: 10px;
          
          &.edited {
            color: white;
            background-image: linear-gradient(to right, $color-brand-01 0%, $color-brand-02 100%);
            
            &:hover {
              background-image: linear-gradient(to right, $color-brand-02 0%, $color-brand-01 100%);
            }
          }
        }
        
        .project-edited-note {
          color: $color-brand-01;
          display: inline-block;

          @include breakpoint(sm) {
            display: none;
          }
        }
      }
      
      button {
        align-self: flex-start;
      }

      > button:not(:last-child){
        margin-right: 2rem;

        @include breakpoint(sm) {
          margin-right: 0;
        }
      }
    }

    .outer-tab-wrap {
      background: $grey-0;
      padding-bottom: 10px;
      padding-top: 2px;

      @include breakpoint(sm) {
        border-top: 1px solid $grey-1;
        padding-bottom: 0;
      }

      .tab-wrap {
        background:  $white;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 0 20px;
        margin: 0;
        border-bottom: 1px solid $grey-2;

        @include breakpoint(md) {
          padding: 0;
        }

        .tab {
          width: convert-rem(180px);
          height: convert-rem(71px);
          color: $black;
          line-height: 46px;
          border-color: transparent;
          border-bottom-color: $black;
          border-style: solid;
          background: transparent;
          font-weight: 400;
          flex-grow: 0;
          outline: none;
          cursor: pointer;
          padding: convert-rem(5px) convert-rem(20px);
          border-width: 0;
          transition: 0.2s ease all;
          
          @include breakpoint(lg) {
            min-width: 0;
          }

          @include breakpoint(sm) {
            width: 100%;
            line-height: 1.1;
            border-radius: 5px;
          }

          &.active {
            font-weight: 500;
            padding: 0 convert-rem(20px);
            border-width: convert-rem(5px);
          }
        }
        
        .element-grid-filter {
          margin: 0 0 0 auto;
          align-self: center;
          
          .dropdown__container {
            margin: 0;
            border: 0;
            outline: none;
            box-shadow: none;
            padding-left: 32px;
            
            &:before {
              font-family: 'lightning-icons';
              content: '\ea5c';
              margin-right: 12px;
            }
          }
        }
      }
    }

    .tab-content {
      width: 100%;
      flex-grow: 1;
      overflow: auto;
    }
  }
}

.project-editing-not-allowed-warning {
  height: 35px;
  background-color: #3653BA;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  .dismiss-btn {
    cursor: pointer;
  }

  &.green {
    color: black;
    background-color: #E2F79F;
    .dismiss-btn {
      position: fixed;
      right: 5px;
      font-size: 16px;
      margin-bottom: 4px;
      margin-left: $space-md;
      margin-right: $space-md;
    }
  }
  
  h6 {
    font-weight: normal;
  }
}

.revision-list-table {
  width: 100%;
  background-color: white;
  border-radius: 15px;
  margin-top: -20px;
  height: 150px;
  overflow-y: auto;

  .revision-list-item {
    border: 2px solid transparent;
    border-radius: 5px;
    transition: 0.2s ease all;
    padding: 0 10px;

    .list-item-wrap {
      display: grid;
      grid-template-columns: [first] 50% [second] 25% [third] 25% ;
      width: 100%;
      border-bottom: 1px solid $grey-1;
      padding: 10px 0;
      align-items: center;
      
      &.header {
        font-weight: 500;
        border-bottom-width: 2px;
        border-bottom-color: $grey-6;
      }
      
      &:not(.header) {
        .previous-revisions {
          font-weight: 500;
        }
        .created {
          font-style: italic;
        }
      }
    }
  }
}

// Modal styling
.ReactModal__Overlay .ReactModal__Content {
  // Hide the file input, so we can replace it with a prettier label
  // Some code taken from this tutorial: https://tympanus.net/codrops/2015/09/15/styling-customizing-file-inputs-smart-way/
  .axial-loads-input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  
  .axial-loads-label {
    display: block;
    margin-top: 28px;
    background-color: white;
    
    &:hover {
      border: none;
    }
    
    .axial-loads-label-btn {
      width: 112px;
      margin-right: 7px;
    }
  }
  .cells-changed-note {
    color: $grey-6;
    font-style: italic;
  }
  
  .axial-load-modal-import {
    text-align: center;
    margin: 14px 0;
    padding: 0;
    color: $grey-6;

    &:hover, &:focus {
      border: none;
      text-decoration: none;
      color: black;
    }
  }
}

.temporary-works-overlay{
  z-index: 10;
  bottom: 0;
  right: 0;
  position: absolute;
  width: convert-rem(400px);
  height: convert-rem(125px);
  background-color: rgba(225,229,245,1);
  border-radius: $space-sm;
  margin: $space-sm;
  line-height: convert-rem(23px);
  padding: convert-rem(25px);
  transition: 0.25s;

  &:hover {
    color: rgba(0,0,0,0.20);
    background-color: rgba(225,229,245,0.20);
  }
  .temp-works-text{
    margin: 0;
    .temp-works-data{
      float: right;
    }
  }
}

.cell-count-dropdown {
  margin-right: 2rem;
  position: relative;
  display: inline-block;
  .cell-count-dropdown-content {
    //color: $grey-4;
    box-shadow: inset 0rem 0rem 0rem 0.1rem $grey-4;

    margin: $space-xxs 0;
    display: flex;
    flex-flow: column;
    position: absolute;
    background-color: white;
    min-width: 100%;
    z-index: 10;
    border: $grey-4;
    border-radius: 5px;
    padding: $space-xxs;
    .count-text{
      margin: $space-xxs $space-xxxs;
      .count-number{
        float: right;
      }
    }
  }
}
