.schedule-of-rates-outer-wrap {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	
	
	.schedule-of-rates-inner-wrap {
		flex-grow: 1;
		width: 100%;
		overflow: auto;
		
		
		.schedule-of-rates {
			border-collapse: separate;
			border-spacing: 0;
			
			.detailed-schedule-cell.editable {
				cursor: pointer;

				-moz-user-select: none;
				-webkit-user-select: none;
				-ms-user-select: none;
				user-select: none;
				-o-user-select: none;
				&:hover:not(.selected):not(.error){
					:nth-child(n){
					background-color: $grey-1;
					}
				}
				&.selected td {
					background-color: $grey-6;
					color: $white;
					
					input[type="checkbox"] {
						border-color: $white;
						
						&:after {
							color: $white;
						}
					}
				}
			}

			th, td {
				color: $grey-6;
				border: solid $grey-2;
				border-width: 0 1px 1px 0;
				min-width: 90px;
				text-align: center;
				padding: 2px;
				
				//left borders
				&:first-of-type {
					border-left-width: 1px;
				}

				&.disabled {
					color: $grey-3;
				}
			}

			th {
				background-color: $grey-0;
				position: -webkit-sticky;
				position: sticky;
				z-index: 2;
				font-weight: 500;
			}
			


			// Apply top borders
			// bottom and right borders always apply, left borders are applied above
			tr:first-of-type th {
				border-top-width: 1px;
				height: 38px; // height is fixed to allow second row sticky positioning
				top: 0;
			}
			tr:not(:first-of-type) th {
				// second row headers start in middle of table, so shouldn't have left border
				border-left-width: 0;
				top: 38px;
			}

			td {
				position: -webkit-sticky;
				position: sticky;
				background: $white;
				
				&.edited {
					color: $color-brand-01;
				}
			}

			// Apply top borders
			// bottom and right borders always apply, left borders are applied above
			td:first-of-type, tr:first-of-type th:first-of-type {
				width: 90px; // width is fixed to allow second column sticky positioning
				left: 0;
				z-index: 1;
				background-color: $grey-0;
			}
			td:nth-of-type(2), tr:first-of-type th:nth-of-type(2) {
				left: 90px;
				z-index: 1;
				background-color: $grey-0;
			}
			tr:first-of-type th {
				&:first-of-type, &:nth-of-type(2) {
					z-index: 3;
					background-color: $grey-1;
				}
			}

			.input-group__checkbox {
				margin: 0;
				padding: 0;
				display: inline-block;
				width: auto;
				min-width: auto;
				vertical-align: top;
			}

			.text-tooltip {
				z-index: 10;
				.input-group {
					margin: 5px;
					display: inline-grid;
					min-width: 0;
				}
			}
			.long-column {
				min-width: 180px;
			}
			
		}
	}
}



.shutters-table {
	border-collapse: separate;
	border-spacing: 0;

	th {
		background-color: $grey-0;
		position: -webkit-sticky;
		position: sticky;
		z-index: 2;
		font-weight: 500;
	}

	// Apply top borders
	// bottom and right borders always apply, left borders are applied above
	tr:first-of-type th {
		border-top-width: 1px;
		height: 44px; // height is fixed to allow second row sticky positioning
		top: 0;
	}
	tr:not(:first-of-type) th {
		// second row headers start in middle of table, so shouldn't have left border
		// border-left-width: 0;
		top: 44px;
	}

	td {
		position: -webkit-sticky;
		position: sticky;
		background: $white;
		
		&.edited {
			color: $color-brand-01;
		}
	}

	// Lock shutter cell
	tr:nth-of-type(2) th:first-of-type {
		width: 90px; // width is fixed to allow second column sticky positioning
		left: 0;
		z-index: 4;
		background-color: $grey-0;
	}

	// Overwrite the first row
	tr:first-of-type th {
		z-index: 4;
		background-color: $grey-0;
	}

	// Lock the first column
	td:first-of-type{
		width: 90px; // width is fixed to allow second column sticky positioning
		left: 0;
		z-index: 3;
		background-color: $grey-0;
	}

	th, td {
		color: $grey-6;
		border: solid $grey-2;
		border-width: 0 1px 1px 0;
		min-width: 90px;
		text-align: center;
		padding: 10px;
		
		//left borders
		&:first-of-type {
			border-left-width: 1px;
		}

		&.disabled {
			color: $grey-3;
		}

		&.shutter-name {
			min-width: 160px;
		}
	}

	th {
		background-color: $grey-0;
		position: -webkit-sticky;
		position: sticky;
		z-index: 2;
		font-weight: 500;

		&.elements-per-level-shutters {
			text-align: left;
		}
	}
	
	td.edited {
		color: $color-brand-01;
	}

	.input-group {
		margin: 0;
		padding: 0;
		display: inline-block;
		width: auto;
		min-width: auto;
		vertical-align: top;
	}

	.quantity-input.manual {
		input {
			background-color: #ffffbf;
		}
	}

	.shutter-quantity-header {
		>div {
			display: flex;
			align-items: center;

			>span:first-of-type {
				flex-grow: 1;
			}

			>span:nth-of-type(2) {
				>div {
					display: flex;

					.input-group:first-of-type {
						width: 140px;
						display: flex;
						align-items: center;
						flex-direction: row;

						input {
							width: 60px;
							margin: 0 $space-xxs;
						}
					}

					.input-group:nth-of-type(2) {
						display: flex;
						align-items: center;
					}
				}
			}
		}
	}

	table-layout: auto;
}


.cost-summary-outer-wrap {
	display: block;
	width: 100%;
	height: 100%;
	padding: 50px;
	
	.summary-text {
		margin-bottom: 20px;
	}

	.additional-parts-header {
		display: flex;

		.header-left {
			flex-grow: 1;
		}
		
		.add-part-btn {
			background-color: $grey-2;
			transition: 0s;
			
			&:before {
				transition: 0s;
			}
			
			&.disabled {
				cursor: not-allowed;
				background-image: none;
				color: $black;
				box-shadow: none;
				opacity: 0.5;
			}
		}
	}

	.cost-summary-inner-wrap {
		display: block;
		width: 100%;
		overflow: auto;
		justify-content: center;
		margin-bottom: 40px;
		
		&.additional-parts {
			// overflow: auto is nice, but it breaks the dropdowns. 
			overflow: unset;
		}

		.cost-summary {
			width: 100%;
			border-collapse: separate;
			border-spacing: 0;

			th, td {
				color: $grey-6;
				height: 50px;
				border: solid $grey-2;
				border-width: 0 1px 1px 0;
				min-width: 90px;
				text-align: center;
				padding: 2px;

				//left borders
				&:first-of-type {
					border-left-width: 1px;
				}

				&.disabled {
					color: $grey-3;
				}
			}

			th {
				background-color: $grey-0;
				z-index: 2;
				font-weight: 500;
			}
			
			tr:first-of-type th {
				border-top-width: 1px;
			}

			td:first-of-type, tr:first-of-type th:first-of-type {
				width: 300px;
			}
			
			td.aggregated-total-description {
				border-width: 0 1px 0 0;
				text-align: left;
				font-weight: 500;
				padding-right: 20px;
				text-align: right;
			}
			.aggregated-total {
				font-weight: 500;
			}
		}
		
		&.additional-parts {
			.input-group-block {
				margin: 0;
				min-width: 60px;

				&.input-group__dropdown {
					min-width: 160px;
					
					.dropdown__container {
						min-width: 160px;
					}
				}
				
				> div {
					border: none;
				}
				input {
					margin: 10px;
				}
			}
			
			.button-wrap, .input-wrap {
				padding: 0;
			}
			
			.submit-part-btn {
				height: 100%;
				width: 100%;
				border-radius: 0;
				background-color: $grey-6;
				background-image: none;
				
				&:hover {
					background-color: $grey-10;
				}
			}
			
			th.hidden-placeholder, td.remove-additional-part {
				background-color: transparent;
				border: none;
			}
		}
	}
}