/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* --------------------------------
Sublinks navigation
-------------------------------- */
/*
Sublinks navigation are the lists that displays under an li link. Example of this is found in the admin navigation.
*/

// % protected region % [Change sublinks navigation styles here] off begin
.nav__sublinks {
	opacity: 0;
	background-color: $grey-2;
	color: get-contrasting-text-color($grey-2);
	position: absolute;
	z-index: 3;
	padding: 0;
	overflow: hidden; //do not remove this

	span {
		white-space: nowrap;
	}

	li {
		white-space: nowrap;
		font-weight: $text-light;

		a {
			color: get-contrasting-text-color($grey-2);
		}

		&:last-of-type {
			border-bottom: none;
		}

		&:hover,
		&:active,
		&:focus {
			a {
				color: get-contrasting-text-color($grey-2);
				@include transform-timing($animation-time);
			}
		}
	}

	&.nav__sublinks--visible {
		opacity: 1;

		span {
			opacity: 1;
		}
	}
}
// % protected region % [Change sublinks navigation styles here] end

// % protected region % [Add additional sublinks navigation styles here] on begin
@mixin sublinks-navigation {
	background-color: transparent;
	padding: 0 12px 0 18px;

	&.active, &:hover {
		background-color: transparent;

		&:before {
			width: 6px;
		}

		a {
			color: $grey-6;
		}
	}

	a {
		color: $grey-6;
	}

	&.home {
		//&:before {
		//	content: none;
		//}

		//a {
		//	color: transparent; // We want to hide the text, but not lose the aria label, so we can't do this through FrontendNavLinks
		//	background-image: url("/Assets/aptus-logo-icon-colour.png");
		//	background-size: contain;
		//	background-repeat: no-repeat;
		//	width: 50px;
		//	height: 50px;
		//	transition: 0.3s ease filter;
		//	filter: brightness(0%);
		//	margin: 0 auto;
		//
		//	@include breakpoint(sm){
		//		width: 30px;
		//		height: 30px;
		//	}
		//
		//	&:hover {
		//		filter: brightness(100%);
		//	}
		//}
	}
	&.projects {
		font-weight: 500;
	}
}

// % protected region % [Add additional sublinks navigation styles here] end