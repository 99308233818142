.update-profile {
  padding: 20px 60px;

  .entity-attrs {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 10px;

    .input-group-block {
      width: 50%;
      margin: 0;
      padding: 10px;
    }
  }

  .btn-group .btn {
    min-width: 160px;
  }
}

.export-save { // temp fix to get text within button
  width: 280px !important;
}