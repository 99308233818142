.project-building-overlay {

  opacity: 1;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(68, 68, 68, 0.85);
  cursor: pointer;
  left: 0;
  z-index: 11;

  &.complete {
    opacity: 0;
    transition: 0.5s ease all;
  }

  .overlay-content-wrap {
    position: relative;
    top: 60%;
    left: 10%;
    display: inline-block;
    max-width: 80%;

    .building-text {
      font-size: 50px;
      color: white;

      @include breakpoint(sm) {
        font-size: 40px
      }
    }

    .progress {
      width:400px;
      border-radius:30px;
      background-color: lightgrey;
      overflow: hidden;
      max-width: 100%;

      .progress-bar {
        height: 60px;
        background-image: linear-gradient(to right, $color-brand-02 0%,$color-brand-01 100%);
        border-radius: 30px;
        transition: 0.1s ease width;
      }
    }
  }
}
