/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* --------------------------------
Expanded navigation
-------------------------------- */
/*
Expanded navigation is the vertical navigation that expands displaying titles next to icons.
*/

// % protected region % [Change expanded navigation styles here] on begin
.nav.nav--vertical {
	>ul {
		&:first-of-type {
			margin-top: $space-md;

			//mobile responsive
			@include media(xs, sm, md) {
				margin-top: 0;
			}
		}
	}

	&.nav--collapsed,
	&.nav--expanded {
		display: flex;
		top: 0;
		position: relative;
		z-index: 1;
		overflow: visible;

		//mobile responsive
		@include media(xs, sm, md) {
			position: absolute;
		}

		>ul {
			background-color: transparent;
			padding-top: $space-xs;

			&:after {
				content: '';
				border-bottom: convert-rem(2px) solid $grey-2;
				width: convert-rem(35px);
				display: flex;
				margin: 1.8rem 1.1rem;
				@include media(xs, sm, md) {
					margin: $space-xxs 1.1rem;
				}
			}

			>li {
				position: relative;
				color: $color-secondary;
				//mobile responsive
				@include media(xs, sm, md) {
					position: inherit;
				}

				a {
					display: flex;
					border-bottom: none;
				}
			}

			&:last-of-type {
				margin-top: auto;
			}
		}

		a {
			&:before {
				margin: 0 $space-xs;
			}
		}

		li {
			&:hover,
			&:active,
			&.active,
			&:focus {
				background-color: $grey-1;
			}
		}

		//Expanding arrow at the botome
		>a {
			margin: 0 $space-xxs $space-md;
			cursor: pointer;
			color: $grey-6;

			@include media(xs, sm, md) {
				margin: .5rem $space-xxs;
			}

			&:hover,
			&:active,
			&:focus {
				color: $black;
				border-bottom: none;
			}
		}
	}

	/***** When nav is expanded ******/

	&.nav--expanded {
		min-width: 70vw;
		
		.link-rm-txt-dec {
			display: inline-block;
			position: absolute;
			right: 0;
			
			&:before {
				content: "\ec92";
			}
		}

		ul li.home a {
			width: 90px;
			height: 90px;
			margin: 30px 0 70px;
		}
		
		a {
			justify-content: flex-start;
			font-size: 1.6rem;
			
			span {
				width: 100%;
				opacity: 1;
				margin-right: $space-xxs;
				@include cubic-bezier-ease-in(width, $quick-animation-time);
			}
		}
		
		ul:after {
			margin: calc(0.5 * 1rem) 2rem;
			width: 5rem;
		}
	}

	/****** Collapsed *****/

	&.nav--collapsed {
		//mobile responsive
		@include media(xs, sm, md) {
			align-items: center;
			justify-content: center;
			pointer-events: none;

			>ul {
				padding-top: 0;
			}
		}

		.link-rm-txt-dec {
			order: -1;
		}

		a {
			span {
				width: 0;
				opacity: 0;
				overflow: hidden;
				@include cubic-bezier-ease-in(width, $quick-animation-time);
			}
		}

		.expand-icon {
			pointer-events: auto;
		}
	}

}
// % protected region % [Change expanded navigation styles here] end

// % protected region % [Add additional expanded navigation styles here] on begin
// % protected region % [Add additional expanded navigation styles here] end