.empty-dashboard {
	display: flex;
	position: relative;
	height: 100%;
	width: 100%;

	.empty-dashboard-content {
		height: 100%;
		max-width: 450px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		margin: 0 50px 0 150px;
		z-index: 1;

		h1 {
			font-size: 3rem;
			line-height: 1.1;
		}
	}

	.spacing-div {
		flex-grow: 1;
	}

	.empty-dashboard-background {
		background-image: url("/Assets/cage-illustration.png");
		background-size: contain;
		background-repeat: no-repeat;
		background-position: bottom right;
		position: absolute;
		height: 75%;
		width: 50%;
		right: 0;
		bottom: 0;
	}
}

.dashboard {
	display: flex;
	width: 100%;
	height: 100%;

	.project-selection-section {
		padding: 50px;
		width: calc(100% - 350px);
		margin: 0;

		@include breakpoint(sm) {
			width: 100%;
			padding: $spacing-md;
		}

		.dashboard-header {
			padding: 0 30px;

			.dashboard-title {
				max-width: 70vw;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				@include breakpoint(sm) {
					padding-top: 30px;
				}
			}

			@include breakpoint(sm) {
				padding: unset;
			}

			.dashboard-action-bar {
				display: flex;

				.input-group-block {
					@include breakpoint(sm) {
						width: 100%;
					}
				}


				@include breakpoint(sm) {
					flex-direction: column;
				}

				.new-project-btn {
					@include breakpoint(sm) {
						margin-top: $spacing-md;
					}
				}

				.input-group-block {
					margin: 0 auto 0 0;

					input {
						margin: 0;
					}
				}
			}
		}
		.hover{
			:hover{
				background-color: $grey-1;
			}
		}
		
		.project-dashboard-list {
			table-layout:fixed;
			:nth-child(n) {
				color: black;
			}

			margin-top: $space-lg;
			padding-top: $space-xxs;
			padding-bottom: $space-xxs;

			.project-list-header {
				display: grid;
				grid-template-columns: 4fr 16fr 4fr 1fr;
				justify-items: start;
				padding-top: $space-xs;
				padding-bottom: $space-xs;
				margin-left: $space-lg;
				margin-right: $space-lg;
				border-bottom: solid;

				.project-job-number {
					margin-top: auto;
					margin-bottom: auto;
					margin-left: $space-sm;
					justify-self: start;
				}

				.project-name {
					margin: auto 0;
					align-self: center;
				}

				.project-last-modified {
					margin-top: auto;
					margin-bottom: auto;
					margin-right: $space-sm;
					justify-self: end;
				}
			}

			.project-list-row {
				.project-folder-icon {
					font-size: 24px;
					color: black;
					margin-left: $space-md;
					margin-right: $space-md;
				}

				.project-folder-icon-right {
					float: right;
					font-size: 24px;
					color: black;
					margin-left: $space-md;
					margin-right: $space-md;
				}

				.project-folder-rows {
					padding-top: $space-sm;
					padding-bottom: $space-sm;
					margin-left: $space-lg;
					margin-right: $space-lg;
					border-bottom: $grey-sub-2 solid;
					display: flex;
				}
				.truncate {
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					flex: 1;
				}

				.project-list-item {
					display: grid;
					grid-template-columns: 4fr minmax(0,16fr) 4fr 1fr;
					justify-items: start;
					padding-top: $space-xs;
					padding-bottom: $space-xs;
					margin-left: $space-lg;
					margin-right: $space-lg;
					border-bottom: $grey-sub-2 solid;

					.project-job-number {
						margin-top: auto;
						margin-bottom: auto;
						margin-left: $space-sm;
						justify-self: start;
					}

					.share-list {
						display: flex;
						width: 100%;

						.project-name {
							margin: auto;
							align-self: center;
						}
					}

					.project-last-modified {
						margin-top: auto;
						margin-bottom: auto;
						margin-right: $space-sm;
						justify-self: end;
					}

					.project-active-icon {
						justify-self: center;
						color: red;
						margin-top: auto;
						margin-bottom: auto;
					}
				}
				&:last-child{
					a .project-folder-rows,
					.project-list-item{
						border-bottom: none;
					}
				}
			}
		}
		.project-list-bottom-toolbar{
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			align-items: center;
			justify-items: center;
			.project-list-button-group{
				grid-column-start: 2;
				min-width: 250px;
				.project-list-page-readout{
					margin-left: $space-xxs;
					margin-right: $space-xxs;
				}
			}
			.project-list-page-size{
				align-self: end;
			}
		}
	}

	.project-details-section {
		position: fixed;
		right: 0;
		top: 0;
		display: flex;
		flex-direction: column;
		margin-left: auto;
		padding: 4vh 2rem 2rem;
		justify-content: flex-start;
		background-color: white;
		height: 100%;
		width: 380px;
		min-width: 380px;
		padding-right: 5vh;
		padding-left: 5vh;

		@include breakpoint(sm) {
			display: none;
			width: 100%;
			min-width: unset;
			padding-top: 6vh;
		}
		
		.share-list {
			.organisation-image {
				display: inline-block;
				height: 48px;
				width: 48px;
				margin-right: -8px;
			}
		}

		.project-top-bar {
			display: flex;
			position: relative;

			.project-job-number {
				margin: 0;
				line-height: 40px;
			}

			.actions-dropdown-wrap {
				margin-left: auto;

				.actions-dropdown-btn {
					margin: 0;
					line-height: 16px;
					padding: 12px;
					transition: all 0.25s ease;

					&:hover {
						background-color: $grey-2;
						color: $black;
					}
				}

				.actions-dropdown {
					display: none;
					position: absolute;
					top: 40px;
					right: 0;
					margin-top: 5px;
					z-index: 1;
					overflow: hidden;


					&.active {
						display: flex;
						flex-direction: column;
						border-radius: 0.5rem;
						background-color: $grey-1;
						box-shadow: 0 0 7px 2px (33, 33, 33, .16);
					}

					button {
						border-radius: 0px;
					}

					.delete-project-btn {
						margin-bottom: 0px;
						margin-top: 0px;
						background-color: $grey-1;

						&:hover {
							background-color: $grey-4;
							color: white;
						}
					}

					.duplicate-project-btn {
						margin-bottom: 0px;
						margin-top: 0px;
						background-color: $grey-1;

						&:hover {
							background-color: $grey-4;
							color: white;
						}
					}

				}
			}
		}


		.project-name {
			font-family: $heading-font-family;
			font-weight: 400;
			margin: 0;
			word-break: break-word;
		}

		.project-job-number, .project-suburb {
			font-weight: 500;
			margin: 0;
			word-break: break-word;
		}

		.project-job-number {
			margin-bottom: 15px;
		}

		.project-name {
			font-size: 2rem;
			margin-bottom: 15px;
		}

		.project-suburb {
			margin-bottom: 20px;

			&:before {
				font-weight: 400;
			}
		}

		.input-group.input-group-block {
			margin: 1rem 0 2rem 0;

			label {
				margin-top: .5rem;
				margin-bottom: -.5rem;
			}
			@localhost
			div {
				border-radius: 0.5rem;
			}
		}

		.btn {
			color: $grey-6;
			margin: 4px;
			text-align: left !important;
			line-height: 1.5;
			font-weight: 500;
			justify-content: flex-start;

			&:hover {
				background-color: $grey-2;
				color: $black;
				box-shadow: none;
				border-bottom: 0px;
			}

			&:before {
				font-weight: 400;
			}
		}

		.project-setup {
			text-align: center;
			padding: 10px 0;

			&:hover, &:focus {
				border: none;
				text-decoration: none;
			}
		}
	}
}

//This button will toggle the project details panel on and off on small breakpoints//

.hide-details-mobile-btn {
	display: none;
	padding-left: 0;


	@include breakpoint (sm) {
		display: block;
	}

	&.icon-arrow-left:before {
		position: relative;
		left: 0;
		top: 0;
	}

}

.details-visible-mobile {
	.project-selection-section {

		@include breakpoint (sm) {
			display: none;
		}
	}

	.project-details-section {
		@include breakpoint (sm) {
			display: flex;
		}
	}
}

.no-project-selected {
	margin: auto;
	align-self: center;
	justify-content: center;

	div.select-icon {
		@include lightning-icon(before, hand-point, true);
		text-align: center;
		font-size: 2rem;
		color: $grey-4;
	}

	p {
		color: $grey-6;
		font-size: 1.4rem;
	}
}

.dashboard__actions {
	display: flex;
	justify-content: space-around;
}

.share-project-modal {
	top: 20%;
	max-height: unset; 
	
	.share-permissions {
		font-style: italic;
		
		&:before {
			margin-right: 1rem;
		}
	}
	
	.chips-input-wrap {
		display: flex;
		flex-wrap: wrap;
		grid-area: input;
		padding: 0.8rem 0.8rem;
		margin: calc(1 * 1rem) 0;
		border: 0.0625rem solid lightgray;
		border-radius: 4px;
		cursor: text;

		.chip {
			display: inline-block;
			padding: 4px 8px;
			background: lightgray;
			border-radius: 5px;
			margin: 2px;

			.click-to-clear {
				position: unset;
				padding: 0 0 0 8px;

				&:after {
					margin: 0;
				}
			}
		}

		input {
			width: 130px;
			flex-grow: 1;
			line-height: 1.6;
			padding: 4px 6px;
			margin: 2px;
			border: none;
			outline: none;

			&:hover, &:focus, &:active {
				border: none;
				outline: none;
			}
		}
	}
	
	.share-list {
		border-top: 2px solid $grey-6;
		border-bottom: 1px solid $grey-2;
		max-height: 220px;
		overflow-y: auto;
		
		.share-item {
			display: flex;
			flex-direction: row;
			border-bottom: 1px solid $grey-2;
			padding: 14px 4px;
			align-items: center;
			
			&:last-of-type {
				border-bottom: none;
			}
			
			.share-details {
				flex-grow: 1;
				
				.organisation-name {
					font-weight: 500;
				}
			}
			
			.share-status {
				min-width: 120px;
				text-align: right;
				font-style: italic;
				
				&.failed {
					color: $color-brand-01;
				}
			}
		}
	}
}