/* --------------------------------
Login
-------------------------------- */
/*
Login, register and reset password pages
*/

.login,
.reset-password,
.register {
  display: flex;
  align-items: flex-start;
  align-content: center;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
  height: auto;
  justify-content: center;

  @include media(xs, sm, md) {
	height: auto;
	margin: $space-sm auto;
  }

  h2 {
	margin: 0 0 $space-lg 0;

	@include media(xs, sm, md) {
	  margin: 0 0 $space-md 0;
	}
  }

  .input-group {
	width: 100%;
	max-width: unset;
	margin: .5rem 0;

	label {
	  font-weight: $text-regular;
	}
  }

  .btn-group {
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: center;
	margin-top: $space-md;

	@include media(xs, sm, md) {
	  margin-top: $space-xs;
	}

	@media screen and (max-width: 620px) {
	  flex-direction: column;
	}

	.btn {
	  padding: $space-md;
	  display: flex;
	  margin: 0;
	  flex-grow: 1;

	  @include media(xs, sm, md) {
		padding: $space-xs;
		margin: $space-xs auto;
	  }

	  @media screen and (max-width: 620px) {
		width: 100%;
	  }

	  &.btn--secondary {
		margin-left: $space-xxxl;

		@media screen and (max-width: 620px) {
		  margin-left: 0;
		}
	  }
	}
  }
}

.register {
  align-items: flex-start;

  a {
	text-decoration: none;
	cursor: pointer;
	min-height: convert-rem(20px);
	margin-bottom: $space-lg;
  }

  h5 {
	margin-top: 0;
  }

  .input-group {
	width: 100%;
  }

  .btn {
	margin: $space-lg;
  }
}

.login__forgot-password {
  display: flex;

  &:hover,
  &:active {
	border-bottom: none;
  }
}

.login-registration-content {
  display: flex;
  height: 100vh;
  width: 100vw;

  @include breakpoint(sm){
	flex-direction: column;
	grid-area: body;
	overflow-y: scroll;
  }

  .info-section {
	display: inline-flex;
	flex-direction: column;
	justify-content: center;
	flex-grow: 1;
	height: 100%;
	vertical-align: top;
	align-items: center;
	background-color: $grey-0;
	position: relative;

	@include breakpoint(sm){
	  background-color: $white;
	  padding: $spacing-md;
	  height: unset;
	  justify-content: flex-start;
	  flex-grow: unset;
	}

	.info-header {
	  align-self: flex-start;
	  font-size: 26px;
	  display: inline-flex;
	  margin: 20px;

	  @include breakpoint(sm){
		align-items: center;
		margin: unset;
		margin-bottom: $spacing-md;
		align-self: unset;
	  }

	  .header-icon {
		height: 66px;

		@include breakpoint(sm){
		  height: 40px;
		}
	  }
	  .header-separator {
		margin: 10px 20px;
		background-color: black;
		width: 4px;
		border-radius: 6px;

		@include breakpoint(sm){
		  height: 2rem;
		}
	  }
	  .header-description {
		line-height: 66px;

		@include breakpoint(sm){
		  line-height: unset;
		  font-size: 16px;
		}
	  }
	}

	.info-body {
	  display: inline-flex;
	  flex-direction: column;
	  justify-content: center;
	  flex-grow: 1;
	  align-items: center;
	  max-width: 412px;

	  @include breakpoint(sm){
		max-width: unset;
		flex-grow: unset;
	  }

	  .info-body-title {
		font-size: 44px;
		font-weight: 500;

		@include breakpoint(sm){
		  font-size: 24px;
		}
	  }
	}

	.spacing-div {
	  flex-grow: 1;
	}

	.background-wrap {
	  background-image: url("/Assets/cage-illustration.png");
	  background-size: contain;
	  background-repeat: no-repeat;
	  position: absolute;
	  bottom: 0;
	  left: -150px;
	  height: 50%;
	  width: 100%;

	  @include breakpoint(sm){
		display: none;
	  }
	}
  }

  .form-section {
	display: inline-flex;
	flex-direction: column;
	width: 450px;
	padding: 10px;
	box-shadow: 3px 0 3px $grey-2;
	overflow: auto;

	@include breakpoint(sm){
	  width: unset;
	  box-shadow: unset;
	  border-top: 1px solid $grey-2;
	  padding-top: $spacing-md;
	}

	form, .registration-confirm, .registration-success {
	  flex-grow: 1;
	  display: flex;
	  align-items: stretch;
	  flex-direction: column;
	  justify-content: center;

	  .organisation-header {
		text-align: center;

		.organisation-data {display: flex;
		  align-items: center;
		  margin-bottom: 32px;

		  .organisation-image {
			width: 52px;
			height: 52px;
			border-radius: 10px;
			box-shadow: 0 0 5px black;
			overflow: hidden;
			margin-right: 8px;
			flex-shrink: 0;
		  }

		  .organisation-name {
			font-size: 32px;
			font-weight: 500;
			min-height: 32px;
			line-height: 32px;
			margin-bottom: 0;
			flex-grow: 1;
			text-align: left;
			padding-left: 8px;
		  }
		}
	  }

	  .entity-attrs {
		overflow-y: scroll;
		scrollbar-width: none; /* Firefox */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */

		&::-webkit-scrollbar { /* WebKit */
		  width: 0;
		  height: 0;
		}

		.input-group-block.email {
		  margin-bottom: 0;
		}
		.email-use-explanation {
		  margin-top: 0;
		  font-size: 14px;
		  font-style: italic;
		  margin-bottom: 20px;
		}
	  }

	  .btn-group {
		padding: 0 20px;
		flex-direction: column;
		align-items: stretch;

		.btn {
		  margin: calc(0.5 * 1rem);
		  padding: calc(1 * 1rem);
		}
	  }

	  > p {
		text-align: center;

		.underlined-link {
		  text-decoration: underline;
		  color: $grey-6;

		  &:hover {
			text-decoration: none;
			border-bottom: none;
			color: $grey-6;
		  }
		}
	  }
	}

	.registration-confirm, .registration-success {
	  width: 80%;
	  margin: 0 auto 50%;

	  h2, p {
		line-height: 1;
		padding: 0 20px;
		text-align: left;
	  }

	  p {
		margin: 20px 0;
	  }
	}
  }
}

//.login-registration-content {
//	.info-section {
//		.background-wrap {
//			//background: red;
//			//background-image: url("../Assets/cage-illustration.png");
//		
//			background-image: url("");
//		}
//	}
//}
//.empty-dashboard .empty-dashboard-background {background-image: url("../Assets/cage-illustration.png");}