// @import '../../frontend/abstracts/mixins.scss';

.edit-view {
  position: fixed;
  display: inline-block;
  color: $color-primary;
  height: 100%;
  width: 0;
  transition: 0.3s ease all;
  background: $white;
  top: 0;
  left: 0;
  z-index: 11;
  box-shadow: 0 0 10px rgba(0, 0, 0, .16);

  &.visible {
    width: 300px;
  }
  &.visible-expanded {
    width: 600px;
  }
  .paste-special-wrapper{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 300px;
    float: right;
    transition: 0.3s ease all;
    .paste-special-inner {
      display: grid;
      grid-template-rows: 1fr 9fr 1fr;
      padding: 1rem;
      height: 100%;
      
      .paste-top {
        display: flex;
        flex-flow: row;
        .paste-header {
          margin-left: 0;
          margin-right: 1rem;
          margin-top: 3rem;
          margin-bottom: 3rem;
        }
        .tooltip{
          flex-grow: 1;
          margin: auto 0 auto 5rem;
        }
        .close-paste-view-btn {
          margin:auto;
          margin-top: 2.2rem;
          margin-right: 1rem;
          width: fit-content;
          height: fit-content;
          color: $grey-4;
          padding: 0;
          vertical-align: top;
          border: none;
          background: transparent;
          cursor: pointer;
          font-size: calc(1rem * (1.333 * 1.333));
          line-height: 40px;
          transition: 0.2s ease all;

          &:after {
            font-size: 1.5rem;
            content: "";
            font-family: 'lightning-icons', 'lato' !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            vertical-align: middle;
            margin-left: var(--space-xxs);
          }

          &:hover {
            color: $grey-6;
          }
        }
      }
      .paste-checkbox{
        margin-left: 2rem;
        margin-top: 3rem;
        margin-bottom: 3rem;
        :nth-child(n){
          border-radius: 5px;
        }
        &.all{
          margin-top: 1rem;
          margin-bottom: 1rem;
          margin-left: 0rem;
        }
        &.paste-error {
          margin-top: -2rem;
          color: red;
          border-radius: unset;
        }
      }
      .bottom-div {
        border-top: solid thin lightgrey;
        padding-top: 1rem;
        align-content: center;
        .paste-cancel {
          width: 100%;
          margin-bottom: convert-rem(5px);
          box-shadow: unset;
        }

        .paste-confirm {
          margin-bottom: convert-rem(5px);
          width: 100%;
          box-shadow: unset;
        }
      }
    }
  }
  .edit-view-inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 300px;
    float: right;
    transition: 0.3s ease all;

    .change-sidebar-width-button {
      position: absolute;
      right: -1rem;
      top: 15rem;
      border-radius: 2rem;
      width: 2rem;
      height: 2rem;
      font-weight: bold;
      font-family: monospace;
      border: none;
      box-shadow: 0px 3px 5px rgba(0, 0, 0, .16);

      &:hover {
        color: white;
        background-color: #3653BA;
      }
    }

    .edit-view-header {
      padding: 35px 20px 25px;

      &.cell:not(.deleted) {
        background-color: $color-secondary;
      }

      .edit-view-title {
        display: inline-block;
        width: calc(100% - 50px);
        margin: 0;
        line-height: 40px;
      }

      .close-edit-view-btn {
        width: 40px;
        height: 40px;
        color: $grey-4;
        padding: 0;
        vertical-align: top;
        border: none;
        background: transparent;
        cursor: pointer;
        font-size: calc(1rem * (1.333 * 1.333));
        line-height: 40px;
        transition: 0.2s ease all;

        &:after {
          font-size: 1.5rem;
          content: "";
          font-family: 'lightning-icons', 'lato' !important;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          vertical-align: middle;
          margin-left: var(--space-xxs);
        }

        &:hover {
          color: $grey-6;
        }
      }
    }

    .edit-view-tab-wrap {
      background: $color-secondary;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      
      .edit-view-tab {
        width: 22%;
        &.setup{
          width: 28%;
        }
        height: convert-rem(50px);
        color: $black;
        line-height: convert-rem(30px);
        border-style: solid;
        border-color: transparent;
        border-bottom-color: $black;
        padding: 5px;
        border-width: 0;
        background: transparent;
        font-weight: 400;
        flex-grow: 1;
        outline: none;
        cursor: pointer;
        transition: 0.2s ease all;
        &.active {
          padding: 0;
          border-width: convert-rem(5px);
          font-weight: 500;
        }
      }
    }

    .socket-extension-options {
      .input-group-wrapper__radio{
        .input-group__radio {
          grid-template-columns: max-content max-content auto;
          width: 100%;
          margin: 0px;
          margin-bottom: $spacing-sm;
        }
      }
      margin-bottom: $spacing-lg;
    }

    .edit-view-tab-content {
      flex-grow: 1;
      overflow-y: scroll;
      padding: 20px;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* Internet Explorer 10+ */

      &::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
      }
      
      .designed-as-box{
        border: 1px solid $grey-2;
        border-radius: 1rem;
        padding: 1rem;
        padding-bottom: 0;
        margin-bottom: $spacing-sm;

        .designed-as-box-right{
          float: right;
          font-weight: bold;
        }
      }
      .force-design-container {
        display: flex;
        .force-design-as-column {
          margin-top: 10px;
          margin-bottom: 20px;
        }
        .tooltip{
          cursor: pointer;
          margin-top: 10px;
          margin-bottom: 20px;
          align-self: center;
          .tooltip__content{
            z-index: 1;
            position: absolute;
            display: none;
            @include fadeInLeft($quick-animation-time);
          }
          &:hover{
            .tooltip__content {
              pointer-events: none;
              right: 3rem;
              background-color: white;
              box-shadow: 0 4px 10px rgb(0 0 0 / 10%);
              padding: 1rem;
              display: block;
            }
          }
        }
      }
      .wall-or-column-dropdown-wrap {
        max-width: 100%;
        display: flex;
        align-items: center;
        transition: 0.2s ease all;
        .wall-or-column-dropdown {
          margin-top: 0;
          margin-bottom: 0;
          float: left;
          &.shrink {
            min-width: 80%;
          }
        }
        .reset-dropdown-selection {
          margin-top: -0.5vh;
          width: 10%;
          height: 10%;
          color: $grey-4;
          padding: 0;
          border: none;
          background: transparent;
          cursor: pointer;
          font-size: calc(1rem * (1.333 * 1.333));
          line-height: 40px;

          &:after {
            font-size: 1rem;
            content: "";
            font-family: 'lightning-icons', 'lato' !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            vertical-align: middle;
            margin-left: var(--space-xxs);
          }

          &:hover {
            color: $grey-6;
          }
        }
      }

      h5 {
        margin: 8px 0;
      }

      .insitu-element {
        margin-bottom: $space-xs;
      }
      
      .apply-load {
        padding-top: $spacing-sm
      }

      .tab-wrap {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .edit-view-tab {
          line-height: 46px;
          background-color: $white;
          border: 1px solid $grey-2;
          margin: 0;
          color: $grey-4;
          flex-grow: 1;
          outline: none;
          cursor: pointer;
          transition: 0.2s ease all;

          &:hover {
            color: $grey-6;
            background-color: $grey-0;
          }

          &.active {
            background-color: $grey-6;
            color: $white;
            border-color: $grey-6;
          }
        }
      }

      .design-configuration-tab-wrap .edit-view-tab {
        &:first-child {
          border-top-left-radius: 5px;
          border-right-width: 0;
        }

        &:last-child {
          border-top-right-radius: 5px;
          border-left-width: 0;
        }
      }

      .element-shape-tab-wrap {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .edit-view-tab {
          line-height: 34px;
          font-weight: 400;
          background-color: $white;
          border: 1px solid $grey-2;
          margin: 0;
          color: $grey-4;
          flex-grow: 1;
          outline: none;
          cursor: pointer;
          transition: 0.2s ease all;

          &:first-child {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            border-right-width: 0;
          }

          &:last-child {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }

          &:hover {
            color: $grey-6;
          }

          &.active {
            background-color: $grey-2;
            border-color: $grey-2;
            color: $grey-6;
            font-weight: 500;
          }
        }
      }

      .design-configuration-tab-content {
        border-width: 0 1px 1px;
        border-style: solid;
        border-color: $grey-2;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        display: flex;
        overflow: hidden;
        margin-bottom: $spacing-sm;

        p {
          margin: 0;
          padding: 10px;
          flex-grow: 1;
        }
      }

      .dimensions-wrap {
        display: flex;

        .input-group {
          min-width: 0;
        }

        .width-depth-divider {
          width: 40px;
          text-align: center;
          margin: 4.8rem auto 0;
          font-weight: 700;
        }
      }

      .dimensions-error-text {
        color: $color-brand-02;
        margin-top: -1.6rem auto 0;
      }

      .override-ssl.input-group {
        margin-bottom: 20px;
      }

      .show-advanced-btn {
        border: none;
        background: none;
        color: $color-brand-02;
        text-decoration: underline;
        margin-left: 50%;
        width: 50%;
        text-align: right;
        cursor: pointer;
        outline: none;
        float: right;

        &:hover {
          text-decoration: none;
        }

        &:after {
          display: inline-block;
          margin-left: 1rem;
        }
      }

      .advanced-dimensions-inputs {
        .corbel-config-checkbox, .void-config-checkbox {      
          padding-top: 8px;
          padding-bottom: 8px;
          & > label {
            // manually style checkbox text to be h5
            padding-top: 2px;
            font-size: $text-md;
            font-weight: $text-regular;
            @include media(xs, sm, md) {
              font-size: $text-xs;
            }
          }
        }

        .dimensions-wrap {
          align-items: top;

          .input-group__checkbox {
            padding-top: 15px;
          }

          .width-depth-divider {
            width: 20px;
          }
        }
        .input-group.input-group-block {
          width: 146px;
          margin: 0px;
        }

        .reo-rate-input {
          width: 121px;
        }
      }
      
      .above-approved-cell-note, .override-parts-note {
        color: $grey-8;
        opacity: 0.8;
        margin-top: 14px;
        
        &:before {
          margin-right: 0.7rem;
        }
      }
      
      .above-approved-cell-note {
        margin-top: -21px;
        margin-bottom: 28px;

        &.beneath-checkbox {
          margin-top: -7px;
        }
      }

      .show-advanced-section {
        display: none;

        &.active {
          display: block;
        }
      }

      .axis-section {
        &.minor {
          margin-top: 25px;
        }
      }

      .axis-moment {
        margin-bottom: 0;
      }

      .interaction-curve-wrap {

        p {
          font-weight: 700;
        }

        canvas {
          margin-bottom: 10px;
        }
      }

      .propping-check-ratio, .axial-check-ratio {
        .label {
          font-weight: 700;
        }
        .percent.error {
          color: $color-brand-01;
        }
      }
    }

    .edit-view-actions {
      padding: 15px;
      display: flex;
      flex-wrap: wrap;
      border-top: 1px solid $grey-1;
      
      .merge-cell-wrap {
        flex-grow: 1;
        display: flex;
        position: relative;

        .merge-explanation-tooltip {
          position: absolute;
          background-color: white;
          box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
          width: 100%;
          bottom: 84px;
          padding: 12px;
          font-size: 12px;
          opacity: 0;
          transition: 0.2s ease all;
          pointer-events: none;
        }
        
        .merge-cell-btn {
          width: 100%;
          flex-grow: 1;
          
          &:disabled {
            cursor: not-allowed;

            &:hover + .merge-explanation-tooltip {
              opacity: 1;
              bottom: 54px;
            }
          }
        }
      }

      .btn {
        flex-grow: 1;
        border-radius: 5px;
        margin: 5px;
        padding: 10px;
        
        &.split-cell-btn, &.merge-cell-btn {
          transition: none;
        }

        &.approve-cell-btn, &.save-cell-btn {
          width: 100%;
        }

        &.btn--icon:before {
          position: relative;
          left: 0;
          right: auto;
          transform: translateY(0px);
          margin-right: 1rem;
        }
      }
    }

    .input-group__checkbox {
      margin: 0;
      flex-direction: row;
      display: flex;
      width: 100%;

      input {
        margin: 0 12px 0 0;
      }

      label {
        margin: 0 auto 0 0;
      }

      .tooltip:after {
        margin: 0;
      }
    }
  }
  .load-transfer-wrap{
    display: grid;
    grid-template-columns: 1fr 1fr;

    .load-transfer-name {
      margin-top: unset;
      margin-bottom: unset;
      min-width: unset;
      width: 100%;
      margin-right: $space-xs;

      :nth-child(n) {
        min-width: fit-content;
      }
    }

    .load-transfer-percentage{
      min-width: unset;
      max-width: 100%;
      margin-top: unset;
      margin-bottom: unset;
      margin-left: $space-xs;
    }
  }
}

.elements-grid {
  $cell-width: 192px;
  $cell-height: 112px;
  $column-height: 60px;
  $level-width: 140px;

  $cell-width-simplified: 152px;
  $cell-height-simplified: 92px;
  $column-height-simplified: 50px;
  $level-width-simplified: 120px;

  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;

  display: inline-block;
  white-space: nowrap;

  .grid-view-column-type {
    display: inline-block;
    vertical-align: top;

    .grid-view-table-gap {
      display: inline-block;
      width: 8px;
    }

    &:last-of-type .grid-view-table-gap {
      display: none;
    }
  }

  .grid-view-column {
    width: $cell-width;
    display: inline-block;
    vertical-align: top;
    white-space: normal;
    line-height: 0; // I don't know why this is required, but styling breaks otherwise. We overwrite it again below.

    .corner, .level, .column, .cell {
      display: inline-flex;
      vertical-align: top;
      background-color: $white;
      line-height: 1;

      &.selected {
        background-color: $grey-6;
        color: $white;
      }

      .content {
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        flex-grow: 1;
        line-height: 1.5;
      }
    }


    .column, .level {
      color: $grey-6;
      position: relative;
      font-weight: 500;

      .content {
        z-index: 5;
        padding: 3px;
        height: 100%;
        text-align: center;
        color: $grey-6;
      }

      &.selected .content {
        color: $white;
      }
    }

    .cell {
      z-index: 1;
      height: $cell-height;
      width: $cell-width;
      position: relative;
      overflow: hidden;
      cursor: pointer;

      &.approved:not(.selected):not(.error) {
        background-color: $grey-0;
      }

      .content {
        line-height: 1.1;
        align-self: stretch;

        div {
          text-align: center;
        }
        
        .cell-contents {
          flex-grow: 1;
          justify-content: center;
          display: flex;
          flex-direction: column;

          .cell-corbel-void {
            position: absolute;
            top: 0px;
            left: 10px;
            background-color: white;
            border: 1px solid lightgrey;
            padding: 1px;
            color: $color-brand-03;
          }
        }

        .cell-transition {
          border-top: 1px solid lightgrey;
          height: 15px;
          font-size: 12px;
        }
      }

      &.merged {
        display: none;
      }

      &.ground:not(.selected):not(.error) {
        background-color: $grey-0;
      }

      // It's frustrating to have to do these individually, but CSS doesn't yet have the ability to dynamically read the height
      @for $i from 2 through 30 {
        &[data-height="#{$i}"] {
          height: calc(#{$cell-height} * #{$i});
        }
      }

      .engineer-approval-check {
        position: absolute;
        top: -20px;
        right: -20px;
        width: 100px;
        height: 100px;
        z-index: -1;
        fill: $grey-2;
      }

      &.selected .engineer-approval-check {
        fill: $grey-4;
      }

      .dimensions-prompt {
        color: $grey-3;
        margin-bottom: 0;
      }

      &.selected .dimensions-prompt {
        color: $white;
      }

      &.error {
        background-image: linear-gradient(to right, $color-brand-01 0%, $color-brand-02 100%);
        color: $white;

        .content {
          text-align: left;
          justify-content: flex-start;
          padding: 10px;

          .error-header {
            font-weight: 700;
            margin-bottom: 0.5rem;
            
            @include lightning-icon(before, error, true);
            &:before {
              font-size: $text-md;
            }
          }

          .error-description {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;  
            overflow: hidden;
          }

          .open-modal-link {
            position: absolute;
            bottom: 10px;
            text-decoration: underline;
            color: $white;
          }
          
          .dismiss-error-btn {
            position: absolute;
            top: 0;
            right: 0;
            margin: 6px;
            padding: 0;
            background: transparent;
            transition: none;
            
            &:before {
              margin: 0;
              font-size: 16px;
              position: relative;
              right: 0;
              top: 0;
              transform: translateY(0%);
              transition: all 0.5s ease;
              color: $white;
            }
          }
        }

        &.warning {
          color: $black;
          background-image: linear-gradient(to right, $color-support-yellow 0%, $color-support-orange 100%);

          .content {
            .error-header {
              @include lightning-icon(before, warning, true);
            }

            .open-modal-link {
              color: $black;
            }

            .dismiss-error-btn:before {
              color: $black;
            }
          }
        }

        &.selected {
          background: $grey-6;

          &.warning {
            color: $white;
          }

          .content {
            .dismiss-error-btn, .dismiss-error-btn:before, .open-modal-link {
              color: $white;
            }
          }
        }
      }

      .changed {
        padding: 5px;
        border-radius: 100%;
        background: #43a047;
        position: absolute;
        right: 0;
        margin: 2px;
      }
      &:hover:not(.selected):not(.error):not(.temp-works){
        background-color: $grey-1;
      }
      &.temp-works{
        cursor: default;
      }
    }

    .column {
      height: $column-height;
      width: $cell-width;
      z-index: 5;
      background-color: $grey-0;
      cursor: pointer;
      &:hover:not(.selected):not(.error):not(.temp-works){
        background-color: $grey-2;
      }
      &.temp-works{
        cursor: default;
      }
    }

    .level {
      height: $cell-height;
      width: $level-width;
      z-index: 3;
      background-color: $grey-0;

      .level-rl {
        font-weight: 400;
      }

      &:hover:not(.selected):not(.error):not(.temp-works){
        background-color: $grey-2;
      }
      &.ground:not(.selected) {
        background-color: $grey-2;
        &:hover:not(.selected):not(.error):not(.temp-works){
          background-color: $grey-3;
        }
      }
      &.temp-works{
        cursor: default;
      }
    }

    .corner {
      height: $column-height;
      width: $level-width;
      z-index: 4;
      background-color: $grey-0;
      cursor: auto;
    }

    // Sticky headers	
    .corner, .column {
      position: sticky;
      top: 0;
    }

    &.level-headers {
      position: sticky;
      left: 0;
      z-index: 5;
      width: $level-width;
    }

    // Add Column/Level buttons

    .add-btn {
      position: absolute;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      background-color: $color-brand-01;
      color: white;
      text-align: center;
      z-index: 5;
      line-height: 28px;
      font-size: 22px;
      font-weight: 700;
      transition: 0.2s ease all;

      &:hover {
        background-color: $color-brand-02;
        cursor: pointer;
      }
    }

    .add-column, .add-level {
      display: none;
      z-index: 5;
      position: sticky;
    }

    .add-column {
      top: calc(#{$column-height} / 2 - 14px);
    }

    .add-level {
      left: 0;
      top: $column-height;

      .add-btn {
        left: calc(#{$level-width} / 2 - 14px);
      }
    }

    .column:hover ~ .add-column, .add-column:hover {
      display: inline-block;
      z-index: 10;

      &.before .add-btn {
        left: calc(-#{$cell-width} - 14px);
      }

      &.after .add-btn {
        right: -14px;
      }
    }

    .level-header-wrap {
      display: inline-block;
      cursor: pointer;

      &:hover {
        .add-level {
          display: block;

          &.before .add-btn {
            top: -14px;
          }

          &.after .add-btn {
            bottom: -14px;
          }
        }
      }
    }
  }

  // Setting up all the borders, so that they don't double up and cause visual issues
  .corner, .level, .column, .cell {
    border-width: 0 1px 1px 0;
    border-style: solid;
    border-color: $grey-2;
  }

  .level, .corner {
    border-left-width: 1px;
  }

  .column, .corner {
    border-top-width: 1px;
  }

  .grid-view-column-type:not(:first-of-type) .grid-view-column:first-of-type {
    .column, .cell {
      border-left-width: 1px;
    }
  }

  // Smaller cell sizes for simplified table
  &.simple {
    .grid-view-column {
      width: $cell-width-simplified;

      .cell {
        height: $cell-height-simplified;
        width: $cell-width-simplified;

        @for $i from 2 through 30 {
          &[data-height="#{$i}"] {
            height: calc(#{$cell-height-simplified} * #{$i});
          }
        }
      }

      .column {
        height: $column-height-simplified;
        width: $cell-width-simplified;
      }

      .level {
        height: $cell-height-simplified;
        width: $level-width-simplified;
      }

      .corner {
        height: $column-height-simplified;
        width: $level-width-simplified;
      }

      .add-column {
        top: calc(#{$column-height-simplified} / 2 - 14px);
      }

      .column:hover ~ .add-column, .add-column:hover {
        &.before .add-btn {
          left: calc(-#{$cell-width-simplified} - 14px);
        }
      }

      .add-level {
        top: $column-height-simplified;

        .add-btn {
          left: calc(#{$level-width-simplified} / 2 - 14px);
        }
      }

      &.level-headers {
        width: $level-width-simplified;
      }
    }
  }
}

.cell-type-indicator {
  display: inline-block;
  width: 10px;
  flex-shrink: 0;
  background-color: $grey-sub-2;

  &.aptus {
    background-color: $color-brand-01;
    background-image: url("/Assets/chevron-up-white.svg");
  }

  &.custom {
    background-color: $color-brand-03;
    background-image: url("/Assets/line-white.svg");
  }
}

.cell-overridden-exclamation {
  &.icon-only::before{
    margin: auto;
    margin-top: calc(($text-md - $text-sm) * 0.45);
    font-size: $text-sm;
    font-weight: $text-bold;
  }
  width: $text-md;
  height: $text-md;
  left: 20px;
  bottom: 19px;
  border-radius: 50% !important;
  position: absolute;
  background-color: $color-brand-01;
  color: $white;
}

// Manage element grid colour filters
.elements-grid {
  &.filter-default {
    // no styles for the default filter
  }
  &.filter-concrete-strength {
    .level.ground:not(.selected) {
      background-color: $grey-0;
    }
    .cell.ground:not(.selected):not(.error) {
      background-color: $grey-0;
    }
    
    .cell:not(.selected) {
      &.concrete-50 .cell-contents {
        background-color: rgba(246,246,246,0.8);
        color: $black;
      }
      &.concrete-65 .cell-contents {
        background-color: rgba(211,211,211,0.8);
        color: $black;
      }
      &.concrete-80 .cell-contents {
        background-color: rgba(112,112,112,0.8);
        color: $white;
      }
      &.concrete-100 .cell-contents {
        background-color: rgba(54,54,54,0.8);
        color: $white;
      }
    }
  }
  &.filter-bar-config {
    .level.ground:not(.selected) {
      background-color: $grey-0;
    }
    .cell.ground:not(.selected):not(.error) {
      background-color: $grey-0;
    }
    
    .cell:not(.selected) {
      $bars-list: ("N20": #7BB7F8, "N24": #3653BA, "N28": #FDF360, "N32": #FFD340, "N36": #E33956, "N40": #EE4123);
      $quantity-list: ("huge": 1, "large": 0.75, "moderate": 0.55, "small": 0.35, "tiny": 0.15);
      
      @each $bar-name, $bar-value in $bars-list {
        @each $quantity-name, $quantity-value in $quantity-list {
          &.aptus-#{$bar-name}.quantity-#{$quantity-name} .cell-contents {
            $bgColor: rgba($bar-value,$quantity-value);
            background-color: $bgColor;

            // Code was adjusted from https://css-tricks.com/switch-font-color-for-different-backgrounds-with-css/
            /* the threshold at which colors are considered "light" and use a black text. Range: integers from 0 to 100,
              recommended 50 - 70 */
            $threshold: 60;
            $luma: (red($bgColor) * 0.299 + green($bgColor) * 0.587 + blue($bgColor) * 0.114) * alpha($bgColor) * 0.392;
            $opacityAdjustedLuma: ($luma * alpha($bgColor)) + (100 * (1 - alpha($bgColor)));
            --switch: calc((#{$opacityAdjustedLuma} - #{$threshold}) * -100%);
            color: hsl(0, 0%, var(--switch));
          }
        }
      }
    }
  }

  .load-line {
    border-radius: 3px;
    height: 6px;
    margin-top: -6px;
    // margin-bottom: -20px;
    // outline: solid 2px rgba($color-support-purple, 0.5);
    background-color: $color-support-purple;
    // outline: solid 15px rgba(100,100,100,1);
    // outline-offset: -1px;
    opacity: 0.3;
    position: relative;
    z-index: 3;
    
    &:hover {
      z-index: 3;
      opacity: 1;
      outline: solid 3px $color-support-purple;
      outline-offset: -1px;
    }
  }
}

.errors-warnings-modal {
  .modal__content {
    max-height: 280px;
    overflow-y: scroll;
  }

  .error {
    margin-bottom: 10px;
    border-radius: 0.5rem;

    background-image: linear-gradient(to right, $color-brand-01 0%, $color-brand-02 100%);
    color: $white;

    .content {
      text-align: left;
      justify-content: flex-start;
      padding: 10px;

      .error-header {
        font-weight: 700;
        margin-bottom: 0.5rem;
        
        @include lightning-icon(before, error, true);
        
        &:before {
          font-size: $text-md;
        }
      }

      .error-description {
        margin-bottom: 0px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;  
        overflow: hidden;
      }
    }

    &.warning {
      color: $black;
      background-image: linear-gradient(to right, $color-support-yellow 0%, $color-support-orange 100%);

      .content {
        .error-header {
          @include lightning-icon(before, warning, true);
        }

        .open-modal-link {
          color: $black;
        }

        .dismiss-error-btn:before {
          color: $black;
        }
      }
    }
  }

  .modal__actions {
    margin-top: 0;
    display: flex;
    justify-content: space-evenly;
  }
}