// Grid spacing styles
.edit-levels-grid {
  .edit-row, .edit-headers {
    display: grid;
    align-items: stretch;
    grid-template-columns: [first] 16.66% [second] 16.66% [third] 16.66% [fourth] 16.66% [fifth] 16.66% [sixth] 16.66%;

    @include breakpoint (sm) {
      grid-template-columns: [first] 150px [second] 150px [third] 150px [fourth] 150px [fifth] 150px [sixth] 150px;
    }
  }
  .edit-row {
    &.rooftop .no-elements-message {
      grid-column: second / span 2;
    }

    .info-cell-wrap {
      grid-column: first / span 4;
      display: grid;
      grid-template-columns: [first] 25% [second] 25% [third] 25% [fourth] 25%;

      .level-name-code .code {
        min-width: 24px;
        display: inline-block;
        margin-right: 12px;
      }
    }

    .btn-wrap {
      grid-column: fifth / span 2;
      display: grid;
      grid-template-columns: [first] 50% [second] 50%;
      align-self: center;

      @include breakpoint (md) {
        display: block;
      }
      @include breakpoint (sm) {
        display: grid;
      }
    }

    &:not(.selected) {
      &:not(.ground) {
        .info-cell-wrap.level-has-error .info-cell:not(:first-child) {
          background-color: rgba($color-support-red, 0.15);
        }
      }

      &.ground {
        .info-cell-wrap.level-has-error .info-cell:not(:first-child) {
          background-color: rgba($color-support-red, 0.25);
        }
      }
    }
  }
}

.edit-column-types-grid {
  .edit-row, .edit-headers {
    display: grid;
    grid-template-columns: [first] 30% [second] 30% [third] 20% [fourth] 20%;

    @include breakpoint (sm) {
      grid-template-columns: [first] 150px [second] 150px [third] 200px [fourth] 150px;
    }
  }
  .edit-row {
    .info-cell-wrap {
      grid-column: first / span 2;
      display: grid;
      grid-template-columns: [first] 50% [second] 50%
    }
    .btn-wrap {
      grid-column: third / span 2;
      display: grid;
      grid-template-columns: [first] 50% [second] 50%;

      @include breakpoint (md) {
        display: block;
      }
      @include breakpoint (sm) {
        display: grid;
      }
    }
  }
}

// Other styles
.edit-levels-grid, .edit-column-types-grid {
  margin-bottom: 20px;

  .edit-headers {
    color: $grey-6;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .edit-row {
    color: $grey-4;

    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select:none;
    user-select:none;
    -o-user-select:none;

    &:not(.rooftop) {
      .info-cell {
        cursor: pointer;
      }
    }

    &.ground {
      .info-cell {
        background-color: $grey-0;
  
        &.level-name-code {
          background-color: $grey-2;
        }
      }
    }


    .info-cell {
      line-height: 16px;
      padding: 20px 12px;
      position: relative;

      &:after {
        position: absolute;
        right: 12px;
      }
    }

    .level-name-code, .column-type-name-code {
      background-color: $grey-1;
      color: $grey-6;
      font-weight: 500;
    }

    .add-btn-wrap, .remove-btn-wrap {
      align-self: center;

      .add-btn, .remove-btn {
        color: $grey-4;
        padding: 1rem 1rem 1rem 2.5rem;
        transition: 0.2s ease color;
        margin: 0 0.5rem;

        &:before {
          margin-right: 8px;
          text-decoration: none;
          right: auto;
          left: 1rem;
          transition: 0.2s ease color;
        }

        &:hover {
          text-decoration: none;
          color: $black;
          background-color: $grey-1;
        }

        @include breakpoint (lg) {
          padding-left: 2rem;

          &:before {
            left: 0.5rem;
          }
        }
        @include breakpoint (md) {
          padding-left: 2.5rem;

          &:before {
            left: 1rem;
          }
        }
      }
      
      .remove-btn {
        @include breakpoint (lg) {
          margin-left: 2rem;
        }
        @include breakpoint (md) {
          margin-left: 0.5rem;
        }
      }
    }

    // borders
    .info-cell {
      border: 1px solid $grey-2;

      &:not(:first-of-type) {
        border-left-width: 0;
      }
    }

    &:not(:first-of-type) .info-cell {
      border-top-width: 0;
    }

    // selected cells
    &.selected .info-cell {
      background-color: $grey-4;
      color: $white;

      &.level-name-code, &.column-type-name-code {
        background-color: $grey-6;
      }
    }
  }
}

.edit-column-types-grid {
  .edit-row {
    .info-cell {
      padding: 20px 24px;

      &:after {
        right: 24px;
      }

      &.column-type-name-code .code {
        min-width: 36px;
        display: inline-block;
        margin-right: 8px;
      }
    }
  }
}
