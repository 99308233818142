.terms-of-use-wrap {
	width: 100%;
	text-align: center;

	.terms-of-use {
		display: inline-block;
		text-align: left;
		padding: 40px 60px;
		margin: 100px auto;
		max-width: 1000px;
		
		background-color: $white;
		border: 1px solid $grey-2;
		box-shadow: 0 0 3px #f0f0f0;
		
		@media all and (max-width: 1000px) {
			border: none;
			margin: 0;
			max-width: unset;
		}
		
		.alphabetic-list {
			list-style-type: lower-latin;
		}
		
		.return-login-btn {
			margin: 30px auto 0;
			display: block;
		}
	}
}