.user-list {
	display: flex;
	width: 100%;
	height: 100vh;

	.user-selection-section {
		padding: 50px;
		width: calc(100% - 350px);
		margin: 0;

		@include breakpoint(sm){
			width: 100%;
			padding: $spacing-md;
		}

		.user-list-header {
			padding: 0 30px;

			.user-list-title {
				@include breakpoint(sm){
					padding-top: 30px;
				}
			}

			@include breakpoint(sm){
				padding: unset;
			}

			.user-list-action-bar {
				display: flex;
				
				.search-user-list {
					@include lightning-icon(before, hoist, true);
				}

				.input-group-block{
					@include breakpoint(sm){
						width: 100%;
					}
				}


				@include breakpoint(sm){
					flex-direction: column;
				}

				.new-user-btn{
					@include breakpoint(sm){
						margin-top: $spacing-md;
					}
				}

				.input-group-block {
					margin: 0 auto 0 0;

					input {
						margin: 0;
					}
				}
			}
		}

		.user-list-table {
			width: 100%;
			margin-top: $spacing-lg;
			margin-bottom: $spacing-lg;
			background-color: white;
			border-radius: 15px;
			padding: 15px;

			.user-list-item {
				border: 2px solid transparent;
				border-radius: 5px;
				transition: 0.2s ease all;
				padding: 0 10px;

				&:not(.headers) {
					cursor: pointer;
				}

				&.selected {
					border-color: $grey-6;

					.list-item-wrap {
						border-color: transparent;
					}
				}
				&:hover:not(.selected):not(.headers){
					border-color: $grey-1;
					.list-item-wrap{
						border-color: transparent;
					}
				}
				&:hover:not(.headers){
					background-color: $grey-1;
				}
				
				.list-item-wrap {
					display: grid;
					grid-template-columns: [first] 50% [second] 25% [third] 25% ;
					width: 100%;
					height: 64px;
					border-bottom: 1px solid $grey-1;
					padding: 10px 0;
					align-items: center;
					font-weight: 500;

					.user-name, .user-email {
						display: block;
					}

					.user-email {
						font-weight: 400;
					}
				}
			}
		}
	}

	.user-details-section {
		display: flex;
		flex-direction: column;
		margin-left: auto;
		padding: 4vh 2rem 2rem;
		justify-content: flex-start;
		background-color: white;
		height: 100%;
		width: 350px;
		min-width: 350px;
		
		.top-separator {
			flex-grow: 1;
		}
		.bottom-separator {
			flex-grow: 3;
		}

		@include breakpoint(sm){
			display: none;
			width: 100%;
			min-width: unset;
			padding-top: 6vh;
		}

		.input-group.input-group-block {
			margin: 1rem 0 2rem 0;

			label {
				margin-bottom: 0rem;
			}
		}

		.user-setup {
			text-align: center;
			padding: 10px 0;

			&:hover, &:focus {
				border: none;
				text-decoration: none;
			}
		}

		.selected-user-name {
			font-size: 2rem;
			font-family: $heading-font-family;
			font-weight: 400;
			margin: 0 0 15px;
			word-break: break-word;
		}

		.selected-user-email, .selected-user-phone {
			font-weight: 500;
			word-break: break-word;
			margin: 0 0 15px;
		}

		.btn {
			color: $grey-6;
			margin: 10px auto 0 0;
			text-align: left !important;
			line-height: 1.5;
			font-weight: 500;

			&:hover {
				background-color: $grey-2;
				color: $black;
				box-shadow: none;
				border-bottom: 0px;
			}
		}
		
		.user-role.input-group.input-group-block {
			margin-bottom: 0;
			
			label {
				font-weight: 500;
				margin-top: 15px;
			}
			
			.dropdown__container {
				border-radius: 5px;
				border-color: $grey-2;
			}
			
			.menu .item.active {
				font-weight: 500;
			}
		}
		
		.role-desc {
			font-style: italic;
			color: $grey-4;
		}

		.no-selected-user-note {
			margin: auto;
			align-self: center;
			justify-content: center;

			.no-selected-user-icon {
				@include lightning-icon(before, hand-point, true);
				text-align: center;
				font-size: 2rem;
				color: $grey-4;
			}

			p {
				color: $grey-6;
				font-size: 1.4rem;
			}
		}
	}
}

.user-list-modal {
	.email-wrap {
		.input-group-block {
			display: inline-flex;
			width: 50%;
		}
		.email-root {
			display: inline-block;
			font-weight: 500;
			vertical-align: bottom;
			margin: 0 0 45px 20px;
			font-size: 1.2rem;
		}
	}
	
	.user-role {
		margin: 0;
		
		.dropdown__container {
			border-radius: 5px;
			border-color: $grey-4;
			
			.item.active {
				font-weight: 500;
			}
		}
	}

	.role-desc {
		font-style: italic;
		color: $grey-4;
	}
}

//This button will toggle the user details panel on and off on small breakpoints//

.hide-details-mobile-btn{
	display: none;
	padding-left: 0;


	@include breakpoint (sm){
		display: block;
	}

	&.icon-arrow-left:before {
		position: relative;
		left: 0;
		top: 0;
	}

}

.details-visible{
	.user-selection-section{

		@include breakpoint (sm){
			display: none;
		}
	}

	.user-details-section{
		@include breakpoint (sm){
			display: flex;
		}
	}
}
