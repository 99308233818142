.upload-parts-container {
	padding: 20px;
	margin: 20px;
	border: 1px solid black;
	max-width: 600px;
	
	a {
		font-family: "Lato", sans-serif;
		font-size: 13.333px;
		font-weight: 500;
		display: inline-block;
		margin-left: 50px;
		color: black;
		text-decoration: unset;
		
		&:hover {
			border-bottom-width: 0;
			text-decoration: underline;
		}
		
		&.icon-download.icon-left:before {
			margin-right: 12px;
		}
	}
}