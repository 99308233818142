/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
@import 'shared/harmony.scss';
// % protected region % [Add additional imports here] off begin
// % protected region % [Add additional imports here] end

.admin {
	@import 'admin/harmony.scss';
	@import 'admin/import-custom.scss';

	.graphiql-content-container {
		padding: 0;
		height: 100%;
	}

	/* % protected region % [Add extra admin scss here] off begin */
	/* % protected region % [Add extra admin scss here] end */
}

.frontend {
	// % protected region % [If you do not want to include any harmony in the front-end, you can remove the imports below and the scss files] off begin
	@import 'frontend/harmony.scss';
	//styles specific to bot only and not related to harmony
	@import 'frontend/custom-bot-imports.scss';
	// % protected region % [If you do not want to include any harmony in the front-end, you can remove the imports below and the scss files] end

	.layout__horizontal {
		display: flex;
		justify-content: space-evenly;
		align-items: center;
	}

	.layout__vertical {
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
	}

	.style-guide {
		padding: 2vw;
		margin-left: 3vw;
	}

	// % protected region % [Add additional front-end style content here] on begin
	 .body-content {
		 background-color: $grey-0;
 
		 a {
			 color: $color-brand-02;
			 text-decoration: none;
 
			 &:visited {
				 color: $color-brand-01;
			 }
 
			 &:hover {
				 color: $black;
			 }
		 }
	 }
	 // % protected region % [Add additional front-end style content here] end
}

// % protected region % [Customise react-contextify here] off begin
/* -------- CONTEXT MENU -------- */
//currently sitting outside of all body-content - style is super generic
@import 'admin/react-contextify.scss';
// % protected region % [Customise react-contextify here] end

/* % protected region % [Add extra scss here] on begin */
 // disable top bar (We've moved the admin/frontend links into the normal menu, for admins)
 .admin__top-bar {
	 display: none;
 }
 
 // Used in various places for organisation logos (but can be overwritten)
 .organisation-image {
	 width: 48px;
	 height: 48px;
	 border-radius: 50%;
	 box-shadow: 0 0 5px rgba(0,0,0,0.3);
	 overflow: hidden;
	 margin-right: 8px;
	 flex-shrink: 0;
 
	 // Used for question mark if no organisation is available
	 text-align: center;
	 line-height: 48px;
	 font-weight: 500;
 
	 img {
		 width: 100%;
		 height: 100%;
		 object-fit: cover;
	 }
 }
 
 /* % protected region % [Add extra scss here] end */
