.project-wizard-content {
  display: flex;
  height: 100%;
  width: 100vw;
  grid-area: body;

  .project-sidebar {
    display: inline-block;
    background-color: $grey-0;
    width: 300px;
    min-width: 300px;
    padding: 50px 0 50px 15px;
    box-shadow: 3px 0 3px $grey-2;
    z-index: 10;

    @include breakpoint (sm){
      display: none;
    }

    .back-btn-wrap {
      width: 300px;
      margin-left: -20px;

      .back-btn {
        text-align: center;
        color: $grey-6;
        margin-left: 20px;
        margin-top: 5px;

        &:hover, &:focus {
          border: none;
          text-decoration: none;
          color: black;
        }
      }
    }

    .project-wizard-title {
      line-height: 1;
      font-size: 48px;
      font-weight: 400;
      margin-bottom: 30px;
      padding: 0 20px;
    }

    .project-wizard-description {
      margin-bottom: 30px;
      padding: 0 20px;
    }

    .project-wizard-multiselect-note {
      color: $color-brand-01;
      margin-bottom: 30px;
      padding: 0 20px;
    }

    .wizard-step {
      height: 62px;
      padding: 20px;
      color: $grey-6;
      cursor: pointer;


      &:hover {
        .step-number {
          margin-left: 0.5rem;
        }
      }

      .step-number {
        display: inline-block;
        vertical-align: top;
        border: 1px solid $grey-6;
        border-radius: 50%;
        width: 22px;
        height: 22px;
        text-align: center;
        line-height: 20px;
        margin-right: 0.5rem;
        margin-left: 0rem;
        transition: all 0.25s ease-out;
      }

      .step-name {
        display: inline-block;
        vertical-align: top;
        line-height: 22px;
        margin: 0;
        font-weight: 500;
      }

      &.active {
        background-color: $white;
        cursor: auto;
        border-radius: .5rem 0 0 .5rem;

        .step-number {
          border: 1px solid $black;
          background-color: $black;
          color: $white;
        }

        .step-name {
          color: $black;
        }

        &:hover {
          .step-number {
            margin-left: 0rem;
          }
        }
      }

      &.disabled {
        color: $grey-2;
        cursor: auto;

        .step-number {
          border-color: $grey-2;
        }

        &:hover {
          .step-number {
            margin-left: 0rem;
          }
        }
      }
    }
  }

  .main-content {
    display: inline-flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100vh;
    vertical-align: top;
    overflow: auto;

    @include breakpoint(sm){
      height: 100vh;
    }

    .top-bar {
      width: 100%;
      padding: $spacing-md;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: auto;

      .current-step-title {
        display: inline-block;
        line-height: 1.5;
        font-size: 36px;
        text-align: left;
        margin-right: auto;

        @include breakpoint(sm){
          font-size: 1.7rem;
        }
      }
      
      .cancel-btn {
        margin-right: 10px;
      }
    }

    .step-area {
      height: 100%;
      overflow: auto;
      padding-left: 0;


      @include breakpoint(sm){
        padding: unset;
        height: 100%;
      }

      .form-section {
        margin: auto auto 40px;
        max-width: 1060px;
        padding: 0 30px;

        .input-group.input-group__radio.input-group-block {
          padding-top: 15px;
        }

        .section-title {
          margin: 20px 0;
          line-height: 1;
          min-width: 100%;
        }

        .input-group-block, .grid-filler {
          padding: 0 20px 0 0;
          width: 50%;
          margin: 0;
          display: inline-grid;
          position: relative;

          @media only screen and (max-width: 480px) {
            padding: 0 0px 0 0;
            width: 100%;
            margin-bottom: 1rem;
          }
        }
        
        .grid-filler {
          @media only screen and (max-width: 480px) {
            display: none;
          }
        }

        .panel-input-group {
          .input-group-block {
            display: grid;
            width: 100%;
          }
        }

        .ground-level-rl,
        .typical-floor-height{
          margin-top: $spacing-md;
        }

        .generate-levels-bar {
          padding: 10px 20px;
          display: flex;
          justify-content: flex-end;
        }

        div.loading-section {
          display: flex;
          width: fit-content;
          margin-bottom: 20px;
          .column-x {
            min-width: fit-content;
            margin-right: -35px;
            input {
              width: 100px;
            }
          }
          .column-y {
            min-width: fit-content;
            margin-left: 15px;
            margin-top: 20px;
            input {
              width: 100px;
            }
          }
          .x-meters {
            margin-top: 45px;
            margin-left: -45px;
          }
          .y-meters {
            margin-top: 45px;
            margin-left: -10px;
          }
          .x {
            margin-top: 45px;
            margin-left: 10px;
          }
        }
      }

      .header-section {
        display: flex;
        align-items: center;
        flex-flow: wrap;

        .header-text {
          flex-grow: 1;
        }
      }
    }

    .bottom-bar {
      padding: 1rem;
      background-color: $white;
      border-top: 1px solid $grey-2;
      text-align: right;
      height: auto;

      .btn--primary{
        margin-left: $spacing-md;

        &:after {
          right: -6rem;
          position: absolute;
          transition: all 0.5s ease;
        }

        &:hover {
          &:after {
            right: 1rem;
          }
        }

        &.next-btn {
          &:hover {
            padding: 1rem 3rem 1rem 1rem;

            &:before {
              right: auto;
              left: 1rem;
            }
          }
        }
      }

      .previous-btn {

        &:before {
          left: -6rem;
          right: auto;
          position: absolute;
          transition: all 0.5s ease;
        }

        &:hover {
          padding: 1rem 1rem 1rem 3rem;
          background: $grey-1;

          &:before {
            right: auto;
            left: 1rem;
          }
        }
      }
    }
  }
}

.dropdown__container{
  grid-area: input;
}

.project-folder{
  .divider.text{
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
  }
}