/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* --------------------------------
Modal
-------------------------------- */
/*
This is the container for modals, which places them above the other content and applies a light overlay to the content
*/

// % protected region % [Change modal styles here] on begin
.modal-container {
	background-color: rgba($white, 0.75);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(255, 255, 255, 0.75);
}

/*The container for the modal content proper*/
.modal-content {
	position: relative;
	top: 30%;
	border: convert-rem(1px) solid $grey-4;
	background: $white;
	overflow: auto;
	border-radius: $btn-radius;
	outline: none;
	padding: $space-md;
	height: fit-content;
	max-height: convert-rem(500px);
	overflow: auto;
	width: 50%;
	margin: 0 auto;
	z-index: $z-index-modal;

	@include breakpoint(sm) {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: convert-rem(500px);
		width: 60%;
		margin: 2rem;
		left: 0;
		right: 0;
		bottom: 0;
	}

}

.modal {

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;

		.btn {
			padding: 0;
		}
	}

	.input-group__textarea {

		textarea {
			width: 100%;
			max-width: unset;
		}
	}

	&__actions {
		display: flex;
		justify-content: flex-end;
		margin-top: $space-md;

		.btn:not(:last-child) {
			margin-right: $space-md;
		}
	}
}
// % protected region % [Change modal styles here] end

// % protected region % [Add additional modal styles here] on begin
.modal-container {
	z-index: 500;
	background-color: rgba(68, 68, 68, 0.85);

	.modal-content{
		background-color: white;
		border-radius: 10px;

		@include breakpoint(sm) {
			width: 100%;
			margin: 0;
		}
	}

	.modal__header{

		.btn--text{
			position: absolute;
			right: 3rem;
			color: $white;
		}
	}



	.modal__message{
		text-align: left;
	}

	.modal__actions{
		justify-content: center;
		margin-top: $spacing-lg;

		button{
			width: 30%;

			@include breakpoint(sm) {
				width: auto;
			}
		}

		.btn--outline.btn--secondary{
			color: $white;
			box-shadow: inset 0rem 0rem 0rem 0.1rem $white;
		}
	}
}

$space-between-bar-and-wall: 10px;

$aptus-bar-width: 14px;
$aptus-bar-height: 14px;

$non-aptus-bar-width: 10px;
$non-aptus-bar-height: 10px;

$wall-thickness: 2px;

.bar-arrangement-modal-container {
	@media only screen and (min-width: 800px) {
		height: 100%;
		width: calc(100% - 300px);
		top: auto;
		left: auto;
		
		.bar-arrangement-modal-content {
			transform: translate(0, -25%);
			width: 50vw;
			height: 700px;
			max-height: none;
			
			.preview-modal-header {
				h4 {
					display: inline-block;
				}
				
				button {
					float: right;
					color: $grey-4;
					font-size: 1.3rem;
					background: transparent;
					padding: 0.5rem 1rem;

					&:after {
						font-size: 1.5rem;
						content: "";
						font-family: 'lightning-icons', 'lato' !important;
						speak: none;
						font-style: normal;
						font-weight: normal;
						font-variant: normal;
						text-transform: none;
						line-height: 1;
						-webkit-font-smoothing: antialiased;
						-moz-osx-font-smoothing: grayscale;
						vertical-align: middle;
						margin-left: var(--space-xxs);
						margin-bottom: -2px;
					}
				}
			}
			
			.element-container {
				display: flex;
				justify-content: center;
				align-items: center;
				height: 550px;

				.element-wall-outter-layer {
					border: $wall-thickness solid black;
					padding: $space-between-bar-and-wall;
				}
				
				.element-wall {
					position: relative;
					display: flex;
					justify-content: space-between;
					
					.depth-label {
						position: absolute;
						top: 0;
						right: -10px;
						font-weight: bold;
						text-align: center;
						margin-bottom: 0;
					}
					
					.width-label {
						position: absolute;
						top: -40px;
						width: 100%;
						font-weight: bold;
						text-align: center;
						margin-bottom: 0;
					}
					
					&-top, &-bottom, &-left, &-right {
						position: absolute;
						width: 100%;
						display: flex;
						justify-content: space-between;
					}

					&-top {
						left: 0;
						top: 0;
					}

					&-bottom{
						left: 0;
						bottom: 0;
						transform: rotate(180deg);
					}
					
					&-left {
						left: 0;
						bottom: 0;
						transform-origin: ($aptus-bar-height * 0.5) ($aptus-bar-width * 0.5);
						transform: rotate(-90deg);
					}
					
					&-right {
						right: 0;
						bottom: -$aptus-bar-height;
						transform-origin: top right;
						transform: rotate(90deg);
					}
				}
			}
			
			.modal__actions {
				margin-top: 0;
				display: flex;
				justify-content: space-evenly;
				
				p {
					margin-left: 1rem;
					display: inline-block;
				}
			}

			.bar {
				&-aptus, &-non-aptus {
					height: $non-aptus-bar-height;
					width: $non-aptus-bar-width;
					border-radius: 50%;
					display: inline-block;
				}

				&-aptus {
					background-color: #EE4124;
					height: $aptus-bar-height;
					width: $aptus-bar-width;
				}

				&-non-aptus {
					background-color: #3653BA;
				}
			}
		}
	}
}

.modal-preview-button {
	float: right;
}

// % protected region % [Add additional modal styles here] end